@mixin hook-card {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: fit-content;
}

@mixin hook-card-title {
  margin-top: 0px;
  margin-bottom: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
