@import 'legacy/stylesheets/mixins/shadow';
@import 'legacy/stylesheets/helpers/_variables.scss';

#cke_donation_receipt_template_content {
  display: none;
}

.cke_combopanel,
.cke_dialog,
.cke_panel {
  z-index: map-get($zIndices, "popover") !important;
}

.cke_editable_inline:focus {
  outline: none;
}

.cke_float .cke_top {
  margin-bottom: 15px;
  border-radius: 3px;
  @include shadow(1);
}
