.user-search-filters {
  z-index: 2;
  background-color: $background-gray;
  box-shadow: -2px 0px 9px #ccc;
  top: 86px;
  bottom: 0;
  padding-bottom: 6em;
}

.collapsible {
  display: block;
  padding: 1em $base-spacing-unit;
  border-top: 1px solid #d4d4d4;
  color: $dark-half-gray !important;
  font-weight: 500;
  background-color: #f1f3fa;

  @extend .transition-all-150;

  &:hover {
    border-bottom-color: lighten($background-color--blue, 10%);
    background: $background-gray;
    text-decoration: none;
  }

  &:last-child {
    border-bottom: 1px solid #d4d4d4;
  }

  .collapsible__icon-open {
    display: none;
  }
}

[data-toggle='collapse'][aria-expanded='true'] {
  .collapsible__icon-open {
    display: block;
  }
  .collapsible__icon-closed {
    display: none;
  }
}

.collapsible__collapse {
  @extend .transition-all-150;
  background: $background-gray;
  border: 1px solid transparent;
  border-right: none;
}

.margin_top_new_header {
  margin-top: 85px;
}
