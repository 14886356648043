.footer {
  position: relative;
  clear: both;
  color: black;
  font-size: 11px;
  line-height: 20px;
  width: 100%;
  text-align: right;
  //
  a {
    color: black;
  }
  .default {
    border-top: 25px solid rgba(255, 255, 255, 0.6);
  }
  .town-height {
    height: 106px;
    // background: url("~/footer-town.png") repeat-x;
  }
  .foot-container {
    background: white;
  }
}

.partnership {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #7e7e7e;
  padding: 25px 0 20px 0;
  font-size: 13px;
  line-height: 25px;
  a {
    text-decoration: none;
  }
  .container {
    text-align: right !important;
    width: 1280px;
    .fb-like {
      position: absolute;
      left: 100px;
    }
    .iphone_ad {
      font-size: 0;
    }
    .spons {
      margin-right: 25px;
    }
  }
  .sep {
    display: inline-block;
    margin: 0 10px;
    color: #343434;
  }
}
