$form-radio-size: 20px;

$form-background: white;
$form-focus-background: white;

$form-radio-background: #EBEEF4;
$form-radio-checked-background: $global-primary-background;
$form-radio-checked-focus-background: $global-primary-background;
$form-radio-checked-icon-color: white;
$form-radio-disabled-icon-color: #aeaeae;
$internal-form-radio-image: "data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-7 -7 14 14'%3E%3Ccircle r='3' fill='#000'/%3E%3C/svg%3E" !default;
