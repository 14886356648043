.manage-request-modal-content {
  width: 1000px;
  margin-right: +30px;
  padding: 15px 0;
  p {
    font-weight: bold;
    color: $dark-gray;
  }
  .button, a {
    float: right;
    margin-right: 10px;
  }
  .or-separator{
    display: table;
    text-align: center;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    border-top-color: #333;
    border-radius: 8px;
    &:before, &:after{
      content: '';
      display: table-cell;
      background: #AFAFAF;
      width: 50%;
      -webkit-transform: scaleY(0.1);
      transform: scaleY(0.1);
    }
    > span{
      white-space: pre;
      padding: 0 15px;
      color: $dark-gray;
    }
  }

  @include media-query(palm) {
    width: 85vw;

    td {
      display: block;
      clear  : both;
      width  : 100%;
      padding: 6px 12px;
    }

    table {
      border-collapse: separate;
      border-spacing : 0 0.6em;
    }
  }
}

$side-margin-comparator: 70px;

.user-comparator-display__wrapper {
  margin-right: $side-margin-comparator ;
  margin-left: $side-margin-comparator ;
  tr {
    border-bottom: 1px solid #ddd;
    &:last-child { border-bottom: none; }
  }
  td {
    @extend .soft-half--sides;
    @extend .one-third;
  }
  td:nth-child(1) { background-color: #EFEFEF; }
  td:nth-child(2) { background-color: #FFEFCA; }
  td:nth-child(3) { background-color: #FFE6E6; }

  @include media-query(palm) {
    margin-left : 0;
    margin-right: 10px;
  }
}

.user_to_approve .user-comparator-display__wrapper {
  @extend .push--ends;
}

.matched_users .user-comparator-display__wrapper {
  @extend .push--bottom;
  @extend .soft--bottom;
  border-bottom: 2px solid #ddd;
}
