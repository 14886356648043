.email-template {
  box-shadow   : 0px 0px 9px #EDEDED;
  background   : #F5F5F5;
  padding: 1em;
  border: 1px solid #E7E7E7;

  &.email-template--white {
    background: white;
  }
}

.email-template__subject, .email-template__from {
  color: #888;
}

.email-template__block {
  display: block;
  overflow: auto;
}

.email-template__content {
  @extend .soft;
  border-radius: 2px;
  background   : white;
  ul { list-style-type: disc }
  ol { list-style-type: decimal }

  ul,
  ol {
    margin-left: 24px;
    margin-bottom: 8px;
  }

  table td {
    vertical-align: top;
  }
}
