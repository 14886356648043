a[disabled] {
  pointer-events: none;
  cursor  : default;
}
a {
  text-decoration: none;
  cursor         : pointer;
  color          : darken($blue, 9%);

  img {
    vertical-align: middle;
  }


  img { @extend .transition-all-150; }
}

.semi-muted-link {
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.muted-link {
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}

.underline-on-hover {
  &:hover {
    text-decoration: underline;
  }
}
