.ui-slider {
  position        : relative;
  height          : 7px;
  border-radius   : 10px;
  background-color: #CDD1D3;
  text-align      : left;

	.ui-slider-range {
    position        : absolute;
    top             : 0px;
    height          : 100%;
    width           : 0px;
    background-color: $blue;
    height          : 7px !important;
    border-radius   : 10px;
	}

  &.ui-slider-disabled .ui-slider-handle {
    cursor: default;
  }

  .ui-slider-handle {
    position        : relative;
    display         : block;
    height          : 17px;
    width           : 17px;
    margin-left     : -8px;
    top             : -5px;
    outline         : none !important;
    background-color: $white;
    box-shadow      : 0px 3px 14px 0px rgba(0,0,0,0.25);
    border-radius   : 50%;
    cursor          : pointer;
  }
}
