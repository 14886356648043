@import 'legacy/stylesheets/helpers/_variables.scss';

.backoffice-header {
  border-bottom: 1px solid darken($sidebar-color, 10%);
  background   : $hivebrite-blue;
  color        : white;
}

.fd-header {
  background-color: #3d3d3d;
  height          : 35px;
  position        : fixed;
  top             : 0;
  width           : 100%;
  border-bottom   : 1px solid $super-dark-gray;
  z-index         : map-get($zIndices, "pinned");
}

.bo-network-background-color {
  background-color: $bo-header-color;
}

.site-header-wrapper {
  display         : block;
  background-color: $header-color;
  position        : fixed;
  width           : 100%;
  top             : 0;
  z-index         : 10;
}
.site-header {
  width: 100%;
  padding: 0 15px;
  height: 100%;
}
.header__user-picture-wrapper {
  text-align: right;
  &.flexbox__item {
    display: table-cell;
    vertical-align: middle;
  }
  .backoffice-fast-link {
    display:            inline-block;
    background-color:   $button-red-flashy;
    box-sizing:         border-box;
    vertical-align:     middle;
    margin-right:       10px;
    a {
      color:            white;
    }
  }

  .header__user-picture-link {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    vertical-align: -moz-middle-with-baseline;
    img {
      height     : 40px;
      border-radius: 2px;
    }
  }
  .v-middle {
    display: inline-block;
    margin-left: 15px;
    > a {
      padding: 18px 5px;
      color: #fff;
      font-size: 16px;
      i { margin-left: 5px; }
    }
  }
}

.header__current-location {
  a {
    line-height: 0.9;
    height: 25px;
  }
}

.header__title {
  margin       : 0;
  color        : #EEE;
  font-weight  : normal;
  font-size    : 1.4em;
}

.header__search--select {
  width: 6em;
}

.header__search {
  padding-right: 5px;
  input, select {
    padding-top     : 4px;
    padding-bottom  : 4px;
    background-color: #F5F5F5;
  }
}

.header-drop-down {
  position        : absolute;
  top             : $header-height;
  right           : 0;
  background-color: white;
  border          : 4px solid $header-color;
  border-top      : none;
}

.header-drop-down__link {
  display        : block;
  text-decoration: none;
  padding        : 0.3em 1em;
  white-space    : nowrap;
}
