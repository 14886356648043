@mixin hook-button() {
  padding: 0 14.4px;
  border-radius: $button-default-border-radius;
  font-size: $global-font-size;
  font-weight: 500;
  background: #EBEEF4;
  color: #050505;
  white-space: nowrap;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

@mixin hook-button-hover {
  background: #0434f9;
  color: #FFFFFF;
}

@mixin hook-button-primary-hover {
  background: #0434f9;
}

@mixin hook-button-misc {
  // Inverted
  .uk-button.uk-button-inverted {
    background: white;
    color: $button-default-color;
    border: 1px solid $button-default-background;
    box-shadow: none;

    &:hover {
      background: $button-default-background;
      border-color: $button-default-background;
    }
  }

  .uk-button.uk-button-primary.uk-button-inverted {
    background: white;
    color: $global-primary-background;
    border: 1px solid $global-primary-background;
    box-shadow: none;

    &:hover {
      background: $global-primary-background;
      color: white;
    }
  }

  // Group button
  .uk-button-group > .uk-button {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: $button-default-border-radius;
      border-bottom-left-radius: $button-default-border-radius;
    }
    &:last-child {
      border-top-right-radius: $button-default-border-radius;
      border-bottom-right-radius: $button-default-border-radius;
    }
  }
  .uk-button-group > .uk-inline > .uk-button {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  .uk-button-group > .uk-inline {
    border-left: 1px solid white;
  }

  // Disabled button
  .uk-button-primary:disabled {
    background: lighten($button-primary-background, 20%);
    color: $button-primary-color;
  }

  .uk-button-danger {
    background: #FD4329;
  }
  .uk-button-danger:hover {
    background: #D53923;
  }
}
