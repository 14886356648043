.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.flex--vertical {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.space-between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.space-around {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.flex--center {
  -webkit-align-items: center;
  align-items: center;
}

.flex--bottom,
.flex-end {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.flex--top {
  -webkit-align-items: start;
  align-items: flex-start;
}

.flex--baseline {
  -webkit-align-items: baseline;
  align-items: baseline;
}

.flex--justify-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.flex--justify-end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-grow--1 {
  flex-grow: 1;
}

.flex-shrink--0 {
  flex-shrink: 0;
}

.align-self--flex-start {
  align-self: flex-start;
}

.flex--wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.palm--flex-wrap {
  @include media-query(palm) {
    flex-wrap: wrap;
  }
}

.flex-gap--8 {
  gap: 8px;
}
