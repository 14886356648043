$icon-width: 2em;

.icon-checkbox {
  position: relative;
}

.icon-checkbox__input {
  position: absolute;
  left: -99999px;

  /*& ~ .icon-checkbox__label::before {
    @include icon($font-KitIconFont-check-circle);
    position: absolute;
    top: -0.4em;
    right: -0.4em;
    color: $green;
    transition: opacity 0.3s ease-in;
  }

  &:not(:checked) ~ .icon-checkbox__label {
    opacity: 0.25;
  }

  &:not(:checked) ~ .icon-checkbox__label::before {
    opacity: 0;
  }*/
}

.icon-checkbox__label {
  transition: all 0.1s ease-in;
  display: inline-block;
  padding: 0 !important;
  font-size: 1.5em;
  line-height: $icon-width;
  width: $icon-width;
  height: $icon-width;
  text-align: center;
  border-radius: 50%;
  color: $white;
}

.icon-checkbox__label--unselected{
  opacity: 0.25;
}

.icon-checkbox__label--selected::before{
  @include icon($font-KitIconFont-check-circle);
  position: absolute;
  top: -0.4em;
  right: -0.4em;
  color: $green;
  transition: opacity 0.3s ease-in;
}