@mixin hook-nav-sub {
  padding-right: 14px;
}

@mixin hook-nav-parent-icon {
  position: absolute;
  right: 1em;
  top: 26px;
  margin-top: -.8em;
}

@mixin hook-nav-default-item {
  font-size: 14px;
  font-weight: bold;
}

@mixin hook-nav-default-item-active {
  background: #E6EFFD;
}

@mixin hook-nav-misc() {
  .uk-nav li > a {
    display: flex !important;
    align-items: center;
  }

  .uk-parent {
    position: relative;
  }

  .uk-nav-default .uk-sub-active > a {
    color: $global-primary-background;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      left: -13px;
      top: 13px;
      bottom: -1px;
      background: $global-primary-background;
      width: 6px;
      height: 6px;
      border-radius: 6px;
    }
  }
}
