$min-height: 80px;

.anchor-box {
  position: relative;
  background-color: $very-light-gray;
  min-height: $min-height;
  border: 1px dashed darken($light-gray, 10%);
}

.anchor-box--highlighted {
  background-color: lighten($blue, 10%);
}

.anchor-box__item {
  padding: 0.5em;
}

.anchor-box__hint {
  position: absolute;
  margin-top: -0.5em;
  top: 50%;
  right: 0;
  left: 0;
  text-align: center;
  font-style: italic;
  color: $gray;
}

.anchor-box__block-handle {
  cursor: move;
  color: lighten($gray, 10%);
}
