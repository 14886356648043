@import 'legacy/stylesheets/helpers/_variables.scss';

$header-height-large: 120px;
$header-height-small: 96px;

.bo-page-header {
  background: white;
  // border-bottom: 1px solid #EEE;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  padding: $base-spacing-unit;
  position: sticky;
  z-index: map-get($zIndices, "sticky");
  top: 0;
  left: 0;
  right: 0;

  &--with-menu {
    margin-left: $header-width;
  }

  h1 {
    //   font-size    :  1.6em;
    //   font-weight  : 600;
    //   color        : #484848;
    margin-bottom: 0;
    //   padding-top  : 0;
    //   display: inline-block;
  }

  .button,
  .bo-header-action {
    position: absolute;
    right: $base-spacing-unit;
    top: 1.1em;
    @extend .nowrap;
  }

  .bo-header-action .button {
    position: static;
  }

  .breadcrumb {
    margin-left: -$base-spacing-unit;
    margin-right: -$base-spacing-unit;
  }

  .uk-tab,
  .breadcrumb {
    margin-bottom: -$base-spacing-unit - 1px;
  }
}

.has-bo-page-header {
  margin-left: 260px;
  padding: 24px 32px;

  &.react-fullscreen-app {
    padding: 0;
  }
}

.has-bo-page-header--hard {
  padding: $header-height-small 0 0;
}

.bo-page--wrapper {
  padding: 24px 32px 16px 32px;
}

.bo-page--full-height {
  padding: 0;
  position: relative;
  height: auto;
  flex: 1 1 100%;
}

.cke_copyformatting_notification {
  // tldr; This prevents unecessary scrollbars.
  // When the content is 100vh this 1px div was overflowing
  // and forcing unecessary vertical scrollbars to show up
  margin-top: -1px;
}

.bo-header-action-with-breadcrumb {
  display: flex;
  align-items: flex-start;
  right: $base-spacing-unit;
  top: 1.1em;

  .select-local {
    margin-left: auto;
  }
}
