.user-data-section {
  padding-top: 1em;
  padding-bottom: 1em;
}

.user-data-section__header {
  padding-left: 1em;
  padding-right: 1em;
}

.user-data-section__title {
  font-size: 0.9em;
  color: lighten($gray, 5%);
  text-transform: uppercase;
}

.user-data-section__droppable {
  position: relative;
  min-height: 2em;
}

.user-data-section__drop-hint {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-style: italic;
  font-size: 0.9em;
  color: $soft-gray;
}

.user-data-section__droppable--highlight {
  background-color: lighten($blue, 10%);
}

.user-data-section__field {
  background-color: white;
  border-bottom: 1px solid $light-gray;
}
.user-data-section__field--last {
  border-bottom: none;
}


.user-data-section__actions {
  float: right;
}
