@mixin hook-dropdown {
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border: 1px solid #d9e3e9;
  border-radius: 4px;
}

@mixin hook-dropdown-nav-item {
  padding: 6px 24px;
  color: #2b2b2b;
}

@mixin hook-dropdown-nav-item-hover {
  background-color: #f8f9fa;
  color: #2b2b2b;
}
