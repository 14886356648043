$nav-item-padding-vertical: 16px;
$nav-item-padding-horizontal: 30px;
$nav-sublist-item-padding-vertical: 6px;
$nav-sublist-padding-left: 68px;

$nav-default-item-color: #575e65;
$nav-parent-icon-color: #575e65;
$nav-default-sublist-item-color: #575e65;
$nav-default-item-hover-color: $global-primary-background;
$nav-default-item-active-color: $global-primary-background;
$nav-default-sublist-item-hover-color: $global-primary-background;
