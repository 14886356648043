.digest-frequency {
  align-items: center;
  display: flex;
}

.digest-time-day {
  display: flex;

  .uk-form-controls {
    display: flex;
    align-items: center;

    .select2-container {
      width: 50% !important;
    }
  }
}

.digest-next-schedule {
  @extend .bordered;

  padding: 20px;
  width: max-content;

  .next-date-scheduled-digest {
    color: $green;
    font-size: 1.3rem;
    font-weight: 600;
  }
}
