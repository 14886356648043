@import 'legacy/stylesheets/helpers/_variables.scss';

// Search bar
.search-view {
  margin     : auto;
  font-weight: 300;
  position: relative;
}

  .twitter-typeahead{
    width: 100%;
  }

  .search-view__icon {
    position  : absolute;
    right     : 0.4em;
    top       : 50%;
    transform : translateY(-50%);
    font-size : 1.2em;
    z-index   : 1;
    color     : $gray!important;
  }

  // Must add `input` selector to be able to override input[type=text],
  // because [type=text] weight the same as a class
  input.search-view__input {
    transition   : all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    background   : #FAFAFA !important;//lighten($background-color--blue, 5%) !important;
    border       : none;
    color        : $gray!important;
    padding-right: 2em;
    &:focus {
      border: none;
    }   
  }

  #search-input {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $dove;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $dove;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $dove;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $dove;
    }
  }

.search-view-dropdown--header {
  width      : 100%;
  margin-top : 0;

  .search-view-dropdown__dataset--with-left-header {
    margin-left: 10em;
    border-left: 1px solid #E3E3E3;
  }
}
.search-view-dropdown--default,
.search-view-dropdown--targeting,
.search-view-dropdown--user_invitations,
.search-view-dropdown--addAttendee {
  width: 100%;
}

    .search-view-dropdown__section-title {
      position   : absolute;
      left       : -8em;
      color      : #555;
      font-size  : 1.1em;
      width      : 7em;
      font-weight: 500;
      text-align : right;
      padding    : 1.5em 2em 0 1.5em;
    }

    .search-item {
      z-index    : map-get($zIndices, "popover");
      color      : #444;
      display    : block;
      line-height: normal;
      padding    : 0.7em 1.3em;

      &.search-view--selected {
        background-color: $light-gray;
      }

      &.disable {
        background-color: #f2f2f2 !important;
        cursor          : not-allowed !important;
      }

      .disable-label {
        margin   : auto;
        top      : 50%;
        transform: translateY(-50%);
      }
    }

    .typeahead-footer {
      cursor: pointer;
    }
