@import 'legacy/stylesheets/helpers/_variables.scss';

.side-preview__floating-entry-content {
  bottom: 0;
  max-width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: map-get($zIndices, "modal");
}

.side-preview__slider-container {
  min-width: 90%;
  width: 907px;
}

.side-preview__close-button {
  position: absolute;
  left: -45px;
  width: 45px;
  height: 53px;
  background: white;
  text-align: center;
  line-height: 53px;
  border-radius: 5px 0 0 5px;

  i {
    font-size: 15px;
    cursor: pointer;
  }
}

.side-preview__header {
  border: 0px;
  border-bottom: 1px solid #EFEFEF;
  border-radius: 0px;
  z-index: 300;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: $header-height;
  background-color: #FFFFFF;
  clear: both;
}

.side-preview__entry-scroller {
  top: $header-height;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  will-change: scroll-position;
  position: absolute;
  background-color: white;
}


.side-preview__entry-overlay {
  background-color: rgba(0,0,0,.4);
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: map-get($zIndices, "backdrop");
}
