@mixin hook-tab {
  border-bottom: 1px solid #CECECE;
}

@mixin hook-tab-item {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  font-weight: 600;
  margin-bottom: -1px;
}

@mixin hook-tab-item-active {
  border-color: #CECECE #CECECE $background-gray;
  background-color: $background-gray;
}

@mixin hook-tab-item-hover {
  border-color: #CECECE #CECECE #CECECE;
}

@mixin hook-tab-misc {
  .uk-tab-card > * > a {
    background: inherit;
  }

  .uk-tab-card > * > a:hover {
    border-color: transparent;
    border-bottom: 3px solid $blue--300;
  }

  .uk-tab-card > .uk-active > a {
    background: inherit;
    border-color: transparent;
    border-bottom: 3px solid $global-primary-background;
    color: $tab-item-color;
  }
}
