@charset 'UTF-8';

// Packages
@import 'outdatedbrowser/outdatedbrowser/outdatedbrowser.css';
@import 'leaflet/dist/leaflet.css';
@import 'select2/dist/css/select2.css';
@import 'webui-popover/dist/jquery.webui-popover.min.css';

// Reset
@import 'base/reset';
* {
  box-sizing: border-box;
}

// Fonts
@import 'KitIconFont';
@import 'fonts/inter';
@import 'fonts/nunito';

// Variables
@import 'helpers/variables_bo';
@import 'helpers/bo_colors';

// Libs
@import 'libs/bootstrap-progress';
@import 'libs/tooltip';
@import 'libs/popover';
@import 'libs/jquery.fancybox';
@import 'libs/datetimepicker';
@import 'libs/inuit/tools.responsive';
@import 'libs/inuit/responsive-helpers';
@import 'libs/csswizardry-grids';
@import 'libs/bootstrap.collapse';

// Mixins
@import 'mixins/icon';
@import 'mixins/shadow';

// Base
@import 'base/containers';
@import 'base/forms';
@import 'base/headings';
@import 'base/links_bo';
@import 'base/paragraphs';
@import 'base/table';

// Helpers
@import 'helpers/font_helpers';
@import 'helpers/animations';
@import 'helpers/autocomplete';
@import 'helpers/borders';
@import 'helpers/borders_bo';
@import 'helpers/background';
@import 'helpers/display';
@import 'helpers/checkbox';
@import 'helpers/datepicker';
@import 'helpers/dropdown';
@import 'helpers/dropdown_bo';
@import 'helpers/icons';
@import 'helpers/images';
@import 'helpers/input_textarea_bo';
@import 'helpers/opacity';
@import 'helpers/select2';
@import 'helpers/select2_bo';
@import 'helpers/slider_range';
@import 'helpers/spacing';
@import 'helpers/typeahead';
@import 'helpers/tooltip';
@import 'helpers/ckeditor';
@import 'helpers/visibility';
@import 'helpers/scroll';

// Layouts
@import 'layout/global';

// Partials
@import 'partials/objects/slider';
@import 'partials/generic/font_helpers';
@import 'partials/generic/footer';
@import 'partials/objects/header';
@import 'partials/objects/pagination';
@import 'partials/objects/breadcrumb';
@import 'partials/objects/notif';
@import 'partials/objects/autocomplete';

// Components
@import 'components/card';
@import 'components/collapsible';
@import 'components/popups';
@import 'components/email_template';
@import 'components/icon_checkbox';
@import 'components/media_center';
@import 'components/tag';
@import 'components/flex';
@import 'components/flexbox';
@import 'components/labels';
@import 'components/loader';
@import 'components/tabs';
@import 'components/bo_boxes';
@import 'components/alert';
@import 'components/filters';
@import 'components/pages';
@import 'components/user_item';
@import 'components/header_search';
@import 'components/users_listing_horizontal';
@import 'components/code';
@import 'components/custom_attr';
@import 'components/modal_preview';
@import 'components/ckeditor_content';
@import 'components/backoffice/help_card';
@import 'components/backoffice/digest';
@import 'components/backoffice/signup_form_setup';
@import 'components/backoffice/user_fields_list';
@import 'components/backoffice/user_data';
@import 'components/backoffice/user_profile_setup';
@import 'components/extensible_menu';
@import 'components/side_preview';

// BackOffice specific styles
@import 'backoffice/commons';
@import 'backoffice/builder';
@import 'backoffice/sidebar';
@import 'backoffice/manage_request';

// UI KIT
@import 'backoffice/custom_uikit_theme/my_theme';

.backoffice-content {
  display: table; // For IE
  display: flex;
  background-color: $background-gray;
  > div {
    flex-grow: 1;
    display: table-cell; // For IE
    display: flex;
    flex-direction: column;
  }
}

body,
html {
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  background: white;
  color: #42484c;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
}

body, button, input, textarea {
  font-family: 'Inter', 'Nunito', 'Helvetica Neue', Arial, Helvetica;
}

a:hover {
  text-decoration: none;
}

// Overide webui-popover
.webui-popover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.11);
  border-radius: 2px;

  .webui-popover-title {
    font-weight: 600;
    color: $gray;
  }

  .webui-popover-content {
    color: $dark-gray;
  }
}
// !Overide webui-popover

.cursor--move {
  cursor: move;
  cursor: -webkit-grabbing;
}

.medium-help-icon {
  font-size: 2em;
  color: $gray;
}
.dropzone {
  border-style: dashed;
  border-width: 2px;
}

.sortable-ghost {
  background: $blue !important;

  td {
    opacity: 0;
  }
}

.select2-container .select2-selection {
  max-height: 7vh;
  overflow: auto;
}

strong {
  font-weight: bold;
}
em {
  font-style: italic;
}

// Polyfill :focus-visible
.js-focus-visible :focus:not([data-focus-visible-added]) {
  outline: none;
}

// fix disabled buttons
.uk-button[disabled] {
  opacity: .5;
}

// Increase the max height of all react select (multi-select components) in
// the back office
.select2.select2-container.select2-container--default {
  .select2-selection.select2-selection--multiple {
    max-height: 50vh;
  }
}
