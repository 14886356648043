%gray-box, .gray-box {
  @extend .soft;
  @extend .push--bottom;
  box-shadow   : 0 0 9px #EDEDED;
  background   : #FCFCFC;
  border       : 1px solid darken(#FCFCFC, 8%);
  border-radius: 2px;
}

%white-box, .white-box {
  @extend .soft;
  @extend .push--bottom;
  box-shadow   : 0 0 9px #EDEDED;
  background   : white;
  border       : 1px solid #f2f2f2;
  border-radius: 2px;
}

.muted-box {
  position: relative;

  &::before {
    z-index   : 10;
    content   : '';
    display   : block;
    background: white;
    opacity   : 0.5;
    position  : absolute;
    top       : 0;
    left      : 0;
    bottom    : 0;
    right     : 0;
  }
}

.section-box {
  padding         : 30px;
  background-color: #F8F8F8;

  hr { margin: 1.5em 0; }
}
