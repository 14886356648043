$border-color: #eaeaea;

.card {
  background-color: white;
}
.card--bordered {
  border: 1px solid $border-color;
}
.card--shadow {
  box-shadow: 0 0 5px #efefef;
  border: 1px solid $border-color;
}
.card--rounded {
  border-radius: 3px;
}

.card__header {
  padding: 0.5em 1em;
  border-bottom: 1px solid $border-color;
}

.card__body {
  padding: 1em;
}

.card__body--breath {
  padding: 1.5em;
}

// Overwrite uk-card styles to remove yellow and mimick new DS design
// on legacy HAML views
.uk-active.uk-card.uk-card-default.uk-card-body,
.uk-card.uk-card-default.uk-card-body {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px 0px;
  padding: 24px;
  padding-top: 16px;
  border-radius: 10px;

  .uk-heading-bullet::before {
    margin-right: initial;
    border-left: none;
  }

  .uk-card-title {
    text-transform: initial;
    font-size: 1.53846rem;
    line-height: 1.84615rem;
    color: #2b2b2b;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

// Specific case : uk-card is also used to display a sub-tab list in the group level
// we must not remove the padding in this case
ul.uk-active.uk-card.uk-card-default.uk-card-body,
ul.uk-card.uk-card-default.uk-card-body {
  padding: 8px 0 0 0;
}
