$border-color: #eaeaea;

.signup-form-setup {
  position: relative;
  height: 60vh;
  border: 1px solid $border-color;
}

.signup-form-setup__left {
  position: absolute;
  top: 0;
  right: 60%;
  bottom: 0;
  left: 0;
  overflow: scroll;
  border-right: 1px solid $border-color;
}

.signup-form-setup__right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 40%;
  overflow: scroll;
}

.signup-form-setup__title {
  position: sticky;
  top: 0;
  padding: 0.5em 1em;
  z-index: 1;
  border-bottom: 1px solid $border-color;
  background: white;
}
