// Heading
$base-heading-margin-top: 0px;
$base-heading-font-weight: 600;
$base-heading-color: $heading-gray;

// Link
$base-link-hover-color: $blue--700;

// List
$base-list-padding-left: 0px;

// Margin
$base-margin-vertical: 0px;

// Emphasize
$base-em-color: inherit;
