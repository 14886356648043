@mixin hook-form {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  border: 1px solid #2b2b2b;
  border-radius: 5px;
  color: #050505;
}

@mixin hook-form-focus {
  border-color: $blue;
  color: #050505;
}

@mixin hook-form-radio {
  border-radius: 5px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@mixin hook-form-label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
}

@mixin hook-form-misc {
  .uk-form-controls {
    position: relative;

    input[type='file'] {
      border: inherit;
      padding: 0;
      height: inherit;
      border-radius: 0px;
    }
  }

  textarea.uk-input {
    min-height: 65px;
    line-height: 20px;
    padding: 8px 10px;

    &.textarea-error {
      border: 1px solid $red;
      margin-bottom: 0.5em;
    }
  }

  i.error-format {
    color: $red;
    margin-bottom: 1em;
  }

  .error {
    color: $red;
    font-style: italic;
  }

  .uk-input,
  .tox-tinymce {
    &.field-error {
      border: 1px solid $red;
    }
  }
}
