@import 'legacy/stylesheets/helpers/_variables.scss';

$bo_side_menu_background: #f1f3fa;
$bo_side_menu_active_border_color: #575e65;

%bo-sidebar-width,
.bo-sidebar-width {
  width: $header-width;
}

.bo-sidebar {
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 6px 0px;
  height: 100vh;
  position: fixed;
  top: 0;
  min-width: $header-width;
  max-width: $header-width;
  width: $header-width;
  overflow: hidden;
  @extend %bo-sidebar-width;
  z-index: map-get($zIndices, "sticky");
}
