$card-default-background: #ffffff;
$card-default-color: #050505;

$card-body-padding-vertical: 24px;
$card-body-padding-horizontal: 24px;

$card-body-padding-vertical-l: 24px;
$card-body-padding-horizontal-l: 24px;

$card-title-font-size: 15px;
$card-default-title-color: #2b2b2b;
