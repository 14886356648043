.extensible-menu {
  &__top-item {
    border-top: 1px solid $light-gray;
    border-bottom: none;
    padding: 15px 10px;
    position: relative;

    &--sortable {
      cursor: move;
    }

    i {
      cursor: pointer;
      color: $gray;
    }

    &__title a {
      color: $gray;
      padding-left: 10px;
    }

    &:last-of-type {
      border-bottom: 1px solid $light-gray;
    }
  }

  &__sub-item {
    margin-left: 25px;
    padding: 15px;

    &-title {
      color: $dark-gray;
      font-size: 1.1em;
      padding-bottom: 10px;
    }

    li {
      padding: 10px 0;
      border-top: 1px solid $light-gray;

      &:last-of-type {
        border-bottom: 1px solid $light-gray;
      }
    }
  }
}
