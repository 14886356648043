$border-color: lighten($blue, 30%);
$cell-padding: 0 1em;

.help-card {
  border: 2px solid $blue;
  border-radius: 10px;
  overflow: hidden;
  padding: 16px 20px;
  color: $gray;
  background-color: #FFFFFF;
  margin-bottom: 32px;
}

.help-card-error {
  border-color: $red;
}

.help-card__inner {
  display: table;
  border-collapse: collapse;
  width: 100%;
}

.help-card__body {
  display: table-cell;
}

.help-card__title {
  font-weight: 600;
  font-size: 1.23077rem;
  line-height: 1.84615rem;
  margin-bottom: 12px;
}

.help-card__text {
  color: #050505;
  font-size: 14px;
}

.help-card__right-actions {
  display: table-cell;
  width: 1px;
  white-space: nowrap;
  padding: $cell-padding;
  vertical-align: middle;
  text-align: center;
  border-left: 1px solid $border-color;
}
