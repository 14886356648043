
.code-formatting {
    margin: .5rem 0 .2rem;
    font-size: .75rem;
    line-height: 1.15rem;
    background: #fbfaf8;
    padding: .5rem;
    word-break: normal;
    display: block;
    line-height: 24px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Monaco,Menlo,Consolas,"Courier New",monospace;
    font-weight: normal;
    color: #333;
}

.code-formatting__inline {
    padding-left: 0;
    padding-right: 0;
    font-size: .72rem;
    line-height: .72rem;
    white-space: normal;
    padding: 1px 3px;
    border-radius: 3px;
    display: inline;
}
