@mixin hook-label {
  border-radius: 2px;
  margin-right: 7px;
  margin-bottom: 12px;
}

@mixin hook-label-misc {
  .uk-label-success {
    background: $green;
  }

  .uk-label-danger {
    background: $red;
  }

  .uk-label-warning {
    background: $orange;
  }
}
