@use "sass:math";

$font-primary: 'Inter', 'Nunito', 'Helvetica Neue', Arial, Helvetica,
  Verdana, sans-serif;
$header-font: 'Inter', 'Nunito', 'Helvetica Neue', Arial, Helvetica, Verdana,
  sans-serif;
$header-font-bold: 'Inter', 'Nunito', 'Helvetica Neue', Arial, Helvetica,
  Verdana, sans-serif;

/*
 * Height
 */
$header-height: 65px;
$menu-navigation-height: 45px;
$header-width: 260px;

$iphone-x-menu-height: 86px;

/*
 * Spacing
 */
$base-font-size: 13px !default;
$base-font-size-rem: 1rem !default;
$base-line-height: 16px !default;

$base-spacing-unit: 20px !default;
$half-spacing-unit: 12px !default;
$quarter-spacing-unit: math.div($half-spacing-unit, 2) !default;
$line-height-ratio: math.div($base-line-height, $base-font-size);

/*
 * Others
 */
$brand-radius: 3px;
