.dropdown-menu {
  border-radius: 0.3em;
  box-shadow   : 0 1px 2px 0 #e0e0e0;
  border       : 1px solid #e0e0e0;

  li,
  .tt-suggestion {

    &:hover a, &:hover button {
      background: #ccebf3;
      color: black !important;
    }

    &:first-child {
      border-top-left-radius: 0.3em;
      border-top-right-radius: 0.3em;
    }

    &:last-child {
      border-bottom-left-radius: 0.3em;
      border-bottom-right-radius: 0.3em;
    }

    a, button {
      padding: 1em 2em;
      line-height: 2em;
      font-weight: 600;
    }

    button {
      background: white;
      border: none;
      font-family: "Helvetica Neue", Arial, Helvetica, Verdana, sans-serif;
    }
  }
}

.dropdown .dropdown-menu li:hover {
  background: #ccebf3;
}
