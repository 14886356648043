hr {
  border-color: #EEE;
  border-width: 2px;
  margin      : 3em 0;

  &.hr__smooth {
    border-width: 1px;
    margin      : 2em 0;
  }
}
