.pagination {
  text-align: center;
  list-style: none;
  margin    : 0;

  li {
    display: inline-block;
  }

  em, a, .disabled, .disabled a {
    display: inline-block;
    padding: 0.3em 0.8em;
  }
  em, a {
    border : 2px solid transparent;
    &:hover {
      border: 2px solid transparentize($blue, 0.8);
      text-decoration: none;
      color: $blue;
    }
  }

  .current, .muted a, .active a {
    font-weight: 500;
    border: 2px solid transparentize($blue, 0.2);
    color: $dark-half-gray;
  }

  .disabled, .disabled a {
    border: none;
    color: #CCC;
  }

  i {
    color: $super-blue;
  }
}
