@import "user_profile_setup/user_profile";
@import "user_profile_setup/anchor_box";

$border-color: #eaeaea;

.user-profile-setup {
  font-size: 0.95em;
  position: absolute;
  top: 260px;
  bottom: 20px;
  width: 900px;
  background-color: #fefefe;
  height: auto !important;
}

.user-profile-setup__left {
  position: absolute;
  top: 0;
  right: 75%;
  bottom: 0;
  left: 0;
  border-right: 1px solid $border-color;
  overflow: scroll;
  background-color: white;
}

.user-profile-setup__right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 25%;
  overflow: scroll;
}

.user-profile-setup__left-title {
  position: sticky;
  z-index: 1;
  top: 0;
  padding: 0.5em 1em;
  background: white;
  border-bottom: 1px solid $border-color;
}

.user-profile-setup__available-fields {
  padding: 1em;
}

.user-profile-setup__profile {
  padding: 1em;
}
