.label {
  border       : 1px solid #CCC;
  display      : inline-block;
  padding      : 0 0.4em;
  border-radius: 2px;
  margin       : 0 0.6em 0 0;
}

.label--orange {
  border    : none;
  color     : white;
  background: $orange;
}

.label--green {
  border    : none;
  color     : white;
  background: $green;
}

.label--blue {
  border    : none;
  color     : white;
  background: $blue;
}

.label--red {
  border    : none;
  color     : white;
  background: $red;
}

.label--yellow {
  border    : none;
  color     : white;
  background: darken($yellow, 8%);
}

.label--grey {
  border    : none;
  color     : white;
  background: $heading-gray;
}
