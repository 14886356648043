.loading-modal {
  position:           fixed;
  top:                0;
  left:               0;
  background-color:   rgba(0, 0, 0, 0.7);
  width:              100%;
  height:             100%;
  z-index:            10;

  display:            none;

  .loading-container {
    position:     absolute;
    top:          35%;
    left:         40%;
    width:        320px;
    background-color: rgb(255, 255, 255);

    .text-container {
      padding:    10px;

      img {
        width:    300px;
      }

      .caption-text {
        margin:   10px;

        .strong {
          font-weight: bold;
        }
      }
      .close-modal {
        margin:   20px;
      }
    }
  }
}

.frontoffice-fast-link {
  display:            inline-block;
  box-sizing:         border-box;
  vertical-align:     middle;
  margin-right:       2px;
}

.inline-radio-button {
  display: flex;

  span {
    display    : flex;
    align-items: center;

    label {
      margin-right: 1em;
      padding-left: 0.2em;
    }
    input {
      margin-right : 0.2em;
      margin-bottom: 0;
    }
  }

}

.inline-checkbox-numeric-input{
  display: flex;

  .uk-checkbox{
    margin-top: 0px !important;
  }

  div {
    padding-left: 6px;
    position: relative;

    .uk-form-controls{
      position: absolute;
      top: -10px;
      width: 60px;
    }

    .error{
      margin-left: 65px;
    }
  }

}
