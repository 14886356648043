$border-radius: 3px;
$icon-color: lighten($gray, 15%);
$icon-color-highlight: #31a9c8;

.user-data-field {
  padding: 0.3em 1em 0.3em 0.3em;
}

.user-data-field__left-item {
  padding: 0 0.5em;
  border-right: 1px solid #e8e8e8;
  text-align: center;
}

.user-data-field__title {
  padding: 0 0.7em;
}

.user-data-field__icon {
  display: inline-block;
  font-size: 1.3em;
  line-height: 1.3em;
  color: $icon-color;
}

.user-data-field__loading {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -10px;
}

.user-data-field__handle {
  cursor: move;
  color: $icon-color;
  transition: all 0.2s;

  .user-data-field:hover & {
    color: $icon-color-highlight;
  }
}

.user-data-field__actions {
  opacity: 0;

  .user-data-field:hover & {
    opacity: 1;
  }
}
