@import 'legacy/stylesheets/helpers/_variables.scss';

%floating-label {
  cursor: text;
  position: absolute;
  z-index: 1;
  top: 0.6em;
  right: 3px;
  font-size: 0.9em;
  color: #999;
  font-weight: 400;
  background: white;
  padding: 0em 0.6em;
  line-height: 1.6em;
  border-radius: 1em;
  @extend .nowrap;
  @extend .transition-all-150;

  &.select {
    top: 0.8em;
  }
}

.label-remote {
  z-index: map-get($zIndices, 'tooltip');
  @extend %floating-label;
  .browser-default & {
    top: 2.6em;
  }
  &.display-bottom {
    top: 2.6em;
  }
  .boolean & {
    top: 0;
  }

  &.label-remote--saving {
    color: $orange;
  }
  &.label-remote--success {
    color: $green;
  }
  &.label-remote--error {
    color: $red;
  }
}

.required-label:after {
  content: ' *';
}

.time-picker,
.time-picker .input {
  @extend .nowrap;
  vertical-align: middle;
  .select2-container {
    display: inline-block;
    width: 49% !important;
    max-width: 49% !important;
    min-width: 49% !important;
  }
}

.input-with-icon--left {
  input {
    padding-right: auto !important;
    padding-left: 40px !important;
  }
  i {
    left: 10px;
    right: auto;
  }
}

$error-color: #fd4329;
$error-exclamation: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaXJjbGUtZXhjbGFtYXRpb24iIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaXJjbGUtZXhjbGFtYXRpb24gc2MtZ0V2RWVyIGtKRmhnbCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBjb2xvcj0icmdiKDI1MywgNjcsIDQxKSIgc3R5bGU9Ii0tZmEtYW5pbWF0aW9uLWRlbGF5OiAwOyAtLWZhLWFuaW1hdGlvbi1kdXJhdGlvbjogMnM7IC0tZmEtYW5pbWF0aW9uLWl0ZXJhdGlvbi1jb3VudDogaW5maW5pdGU7IGhlaWdodDogMTZweDsiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTI1NiA1MTJBMjU2IDI1NiAwIDEgMCAyNTYgMGEyNTYgMjU2IDAgMSAwIDAgNTEyem0wLTM4NGMxMy4zIDAgMjQgMTAuNyAyNCAyNFYyNjRjMCAxMy4zLTEwLjcgMjQtMjQgMjRzLTI0LTEwLjctMjQtMjRWMTUyYzAtMTMuMyAxMC43LTI0IDI0LTI0ek0yMjQgMzUyYTMyIDMyIDAgMSAxIDY0IDAgMzIgMzIgMCAxIDEgLTY0IDB6Ij48L3BhdGg+PC9zdmc+');
.error-with-exclamation-icon {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: $error-exclamation;
    margin-right: 8px;
    margin-top: -0.15em;
  }
}

.form-errors__wrapper {
  background-color: rgba($error-color, 0.1);
  border-radius: 5px;
  padding: 4px 12px;

  li {
    line-height: 16px;
    margin: 4px 0px;

    @extend .error-with-exclamation-icon;
  }
}
