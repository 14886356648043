.tabs {
  border-bottom: 1px solid #EEE;
  text-align   : center;
  margin-bottom: 2em;

  .sub-page-customizables li {
    display: block;

    a {
      font-weight: 400;
      padding: 0.2em 1em;
      border-bottom: 2px solid transparent;
    }

    &.active > a {
      font-weight: 400;
      border-bottom: 2px solid $blue;
    }
  }

  li {
    display: inline-block;
    vertical-align: top;

    a {
      color        : #666;
      display      : inline-block;
      padding      : 0.5em 1em;
      position     : relative;
      bottom       : -1px;
      border-bottom: 2px solid transparent;
      font-weight  : 400;
      @extend .transition-all-150;
      &:hover {
        border-bottom-color: transparentize($blue, 0.8);
        text-decoration: none;
      }
    }

    &.active > a {
      font-weight  : 400;
      border-bottom: 3px solid $blue;
    }
  }
}

.tabs--left {
  text-align: left;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}
