.breadcrumb {
  text-align   : center;
  border-bottom: 2px solid $blue;
  padding-top  : 1em;
  color        : lighten($background-color--blue, 30%);

  .icon-chevron-right {
    padding: 0 0.5em;
  }

  a {
    padding                : 0.4em 1.5em;
    display                : inline-block;
    border-top-left-radius : 5px;
    border-top-right-radius: 5px;
    @extend .transition-all-150;

    &:hover {
      background: transparentize($blue, 0.9);
      text-decoration: none;
    }
  }

  .current,
  .current:hover {
    color     : lighten($background-color--blue, 70%);
    background: $blue;
  }
}
