@import 'legacy/stylesheets/helpers/_variables.scss';

.ejs-embed iframe {
  background: white;
}
.media-center__header {
  background: white;

  @extend .bordered--bottom;
  @extend .bordered--left;
  @extend .bordered--right;
}
.dropdown-item--big {
  padding: 14px 23px !important;
  width: 100%;
}
.media-center__container .ejs-embed {
  margin: auto;
}

.media-center__breadcrumb {
  margin: 0;

  a {
    outline: transparent;
  }
}
.media-center__breadcrumb-item {
  font-size: 1.4em;
  color: $blue;
}
.media-center__breadcrumb-item--active {
  color: $gray;
}
.media-center__breadcrumb-item:last-child {
  font-weight: normal;
}

.media-center__file {
  word-break: break-word;
  font-weight: 500;

  &:hover .media-center__content--hover {
    opacity: 0.7;
    transition: 0.5s ease;

    .icon-comment {
      margin-right: 2px;
    }

    .icon-heart {
      margin-left: 2px;
    }
  }

  @include media-query(palm) {
    display: block;
  }
}

.media-center__files-container-header {
  font-weight: 500;
}

.media-center__list-header {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 32px !important;
}

.media-center__file--list {
  padding: 0.8em;
  border-bottom: 1px solid #eeeeee;
  border-radius: 4px;
  border: 1px solid transparent;

  &:hover + .media-center__file--list {
    color: $blue;
    border: 1px solid transparentize($blue, 0.5);
    border-top-color: transparent;
  }

  .media-center__description {
    text-align: justify;
  }

  .media-center__file-icon {
    text-align: center;
    margin-right: 1em;

    > div {
      margin: auto;
    }

    i {
      margin: auto;
    }

    img {
      max-height: 140px;
      margin: auto;
    }
  }
}

.media-center__folder {
  text-align: center;
  margin: auto;

  img {
    height: 160px;
    width: 97%;
    margin: auto;
  }
}

.media-center__file-embed {
  width: auto !important;
}

.media-center__file--thumbnail {
  padding: 1.5em 0em 0em;
  text-align: center;

  @include media-query(palm) {
    padding: 0.5em;
  }
}

.media-center__file-custom-icon {
  height: 50px;
  line-height: 50px;
  width: 50px;
  font-size: 16px;
}

.media-center__file--thumbnail {
  .media-center__file-icon {
    margin: auto;
    font-weight: bold;

    img {
      max-height: 120px;
      background-color: white;
      border-radius: 7px;
      border: 5px solid white;
    }

    @include media-query(palm) {
      height: 100px;
      line-height: 100px;

      img {
        max-height: 120px;
        background-color: white;
        border-radius: 7px;
        border: 5px solid white;
      }
    }
  }

  .media-center__folder-icon {
    line-height: 89px;
  }

  .media-center__file-custom-icon {
    width: auto;
    height: 100%;
    font-size: 2em;
  }

  .media-center__file-custom-icon,
  .media-center__file-icon {
    @include media-query(palm) {
      height: 100px;
      line-height: 100px;

      img {
        height: auto !important;
      }
    }
  }
}
.media-center__file-embed-overflow-layout {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($zIndices, "pinned");
}
.media-center__file-name,
.media-center__file-break {
  overflow-wrap: break-word;
  font-size: 13px;
  min-height: 36px;
  overflow: hidden;
}
.media-center__file-break {
  word-break: normal;
}

.media-center__foldername {
  max-width: 100%;
}

.media-center__filename_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.media-center__file-custom-icon {
  margin: auto;
  height: 45px;
  line-height: 45px;
  width: 45px;
  padding: 0 0.2em;
  text-transform: uppercase;
  word-break: keep-all;
  overflow: hidden;
  font-weight: bold;
  border-radius: 3px;
  background-color: white;
}

.media-center-preview {
  height: 100%;
}
.media-center-preview__sidebar {
  @media screen and (max-width: 600px) {
    width: 200px;
  }
  width: 400px;
  float: right;
}

.media-center__file__preview-container {
  z-index: map-get($zIndices, "modal");
}

.media-center__file-custom-icon--preview {
  height: 100px;
  line-height: 100px;
  width: 100px;
  font-size: 1.5em;
  vertical-align: middle;
  display: inline-block;
}

.media-center__file-embed-overflow-layout.active {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.media-center__file-embed-preview {
  width: 650px;
  margin: auto;
}

.media-center-preview__picture-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: auto;
    max-height: 90vh;
    user-select: none;
  }
}

.media-center-preview__icon_previous,
.media-center-preview__icon_next {
  position: absolute;
  top: 50%;
  bottom: 50%;
  height: 40px;
  margin-top: -20px;
  margin-bottom: -20px;
}
.media-center-preview__icon_previous {
  left: 0;
  margin-left: 15px;
}
.media-center-preview__icon_next {
  right: 0;
  margin-right: 15px;
}
.media-center-preview__icon_rotate {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 28px;
}

.media-center__action-bar {
  display: flex;
  align-items: center;
}

.media-center__file__container {
  a {
    outline: transparent;
  }
}

.media-center__file__container:hover,
.media-center__file__container--active {
  .media-center__overflow-menu {
    display: block;
  }
}

.media-center__file__container--active {
  .media-center__input-name {
    text-decoration: none;
    color: $blue;
    border: 1px solid transparentize($blue, 0.5);
  }
}

.media-center__overflow-menu__content {
  position: absolute;
  min-width: 120px;
  background: #ffffff;
  z-index: map-get($zIndices, "pinned");
  cursor: pointer;
  text-align: left;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16),
    0 8px 16px rgba(27, 39, 51, 0.08);

  &.media-center__overflow-menu__content--list {
    top: calc(50% + 18px);
    left: calc(100% - 38px);
  }
  &.media-center__overflow-menu__content--thumbnail {
    top: 52px;
    left: calc(100% - 39px);
  }

  li,
  a {
    display: block;
    min-height: 30px;
    width: 100%;
    color: black;
    padding: 13px;

    &:hover {
      background: #f7f9fa;
    }
  }
}

.media-center__input-name {
  margin-top: 5px !important;
  padding: 6px !important;
  max-width: 100%;
}

.media-center__input-name--small {
  width: 90% !important;
}

.media-center__folder_form {
  textarea {
    resize: vertical;
  }

  .button[disabled] {
    cursor: not-allowed;
  }
}

.media-center__create-dropdown .button {
  padding: 9px 13px;
}

.media-center__view-type {
  line-height: 35px;
}

.media-center__topic-name {
  max-width: 75%;
}
