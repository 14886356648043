/*------------------------------------*\
     WEIGHTS
\*------------------------------------*/

.f-weight-100    { font-weight: 100; }
.f-weight-300    { font-weight: 300; }
.f-weight-400    { font-weight: 400; }
.f-weight-500    { font-weight: 500; }
.f-weight-600    { font-weight: 600; }
.f-weight-bold   { font-weight: bold; }
.f-weight-normal { font-weight: 400 !important; }


/*------------------------------------*\
     STYLES & VARIANTS
\*------------------------------------*/

.f-style-italic            { font-style    : italic; }
.f-small-caps              { font-variant  : small-caps; }
.text-transform-none       { text-transform: none !important; }
.text-transform-capitalize { text-transform: capitalize; }


/*------------------------------------*\
     DECORATIONS
\*------------------------------------*/

.f-decoration-underlined      { text-decoration: underline; text-underline-position: under; }
.f-decoration-not-underlined  { text-decoration: none !important; &:hover { text-decoration: none !important; } }
.f-white-transparent {
    color: #EEE;
    color: rgba(255, 255, 255, 0.8);
}
.f-color-dark-blue { color: #323a45; }
.f-color-black     { color: black; }
.f-color-white     { color: white; }


/*------------------------------------*\
     FONT-SIZE
\*------------------------------------*/

.inherit-font-size { font-size  : inherit; }
.f-size-really-big {
    font-size: 50px;
    @include media-query(palm) { font-size: 30px; }
}
.f-size-big {
    font-size: 40px;
    @include media-query(palm) { font-size: 30px; }
}
.f-size-20  { font-size: 20px; }
.f-size-16  { font-size: 16px; }
.f-size-14  { font-size: 14px; }
.f-size-13  { font-size: 13px; }
.f-size-12  { font-size: 12px; }
.f-size-11  { font-size: 11px; }
.f-size-10  { font-size: 10px; }
.f-size-9   { font-size: 9px; }

.palm-f-size-12 { @include media-query(palm) { font-size: 12px; } }

.milli p {
  line-height: 1.5;
}
.word-break-normal { word-break: normal; }
.thin              { font-weight: 300; }

.smaller-print {
  font-size: 0.8em;
}

.line-through { text-decoration: line-through; }
.line-through--gray {
  text-decoration: line-through;
  color          : #777;
  font-style     : italic;
}

.base-font-style {
  font-size  : $base-font-size;
  font-weight: 400;
}


/*------------------------------------*\
     LINE HEIGHT
\*------------------------------------*/

.line-height-normal { line-height: normal !important; }
.line-height-1      { line-height: 1   !important; }
.line-height-1-3    { line-height: 1.3 !important; }
.line-height-1-5    { line-height: 1.5 !important; }
.line-height-1-7    { line-height: 1.7 !important; }
.line-height-2      { line-height: 2   !important; }
.line-height-2-5    { line-height: 2.5 !important; }

.palm-text--center { @include media-query(palm) { text-align: center !important; } }
.palm-text--left   { @include media-query(palm) { text-align: left !important; } }
.lap-text--center  { @include media-query(lap) { text-align: center !important; } }

.palm-text--milli {
    @include media-query(palm) { font-size: 0.9em; }
}

.break-word { word-wrap: break-word; }

.no-text-shadow    { text-shadow: none; }
.no-text-transform { text-transform: none; }
.text-ellipsis {
    text-overflow: ellipsis;
    white-space  : nowrap;
    overflow     : hidden;
}

.nowrap    { white-space : nowrap !important; }
.break-all { word-break : break-all; }

.not-outlined { outline: none !important; }
