/**
 * General
 */
$background-color: #fafafa;
$site-background-color: $background-color;
$background-color--beige: #fafafa;
$background-color--blue: #323a45;
$transparent_background_modal: rgba(0, 0, 0, 0.7);

/**
 * Colors
 */
$white: #ffffff !default;
$black: #2f363b !default;
$gray: #717273 !default;
$light-gray: #eeeeee !default;
$smooth-gray: #d7d7d7 !default;
$dark-gray: #353b42 !default;
$blue: #4733ff !default;
$yellow: #f5ca55 !default;
$green: #118906 !default;
$dark-green: #62b165 !default;
$greenblue: #7fd0cd !default;
$orange: #fe9526 !default;
$lightgreen: #7fd082 !default;
$red: #fd4329 !default;
$red-light: #fd4329 !default;
$twitter_blue: #02abee !default;

/**
 * UI KIT
 */
$background-gray: #fbf9f9 !default;
$heading-gray: #2b2b2b !default;
$heading-gray--700: #2b2b2b !default;
$button-color-gray: #313a46 !default;
$button-background-gray: #e3eaef !default;

$blue--300: lighten($blue, 30%);
$blue--700: darken($blue, 15%);

/**
 * Buttons
 */
$btn-white-border: #d3d3d3 !default;
$btn-green: $green;
$btn--disabled: #cacaca !default;

/**
 * Borders
 */
$border-soft-gray: #dadada;

/**
 * Texts
 */
$text-soft-gray: #94989b;
$text-dark-gray: #2f363b;

// // ------------------------------------------------------------ Colors
$white: white !default;
$header-color: #333333 !default;
$darkdark-gray: #222222 !default;
$dark-gray: #444 !default;
$dark-half-gray: #050505 !default;
$soft-gray: #999 !default;
$gray: #ccc !default;
$light-gray: #f5f5f5 !default;
$subtle-gray: #f3f3f3 !default;
$very-light-gray: #fcfcfc !default;
$super-dark-gray: #141414 !default;
$button-normal-color: #8892a0 !default;
$button-green: #5ea348 !default;
$button-blue: #5183b7 !default;
$button-pink: #ff003c !default;
$button-red: #ce4848 !default;
$button-red-flashy: #eb0c27 !default;
$button-yellow: #fdc40f !default;
$button-orange: #e9683d !default;
$hivebrite-blue: #323a45 !default;
$bluey: #339edf !default;
$blue-light: #33b9ec !default;
$super-blue: #00599d !default;
$blue-super-light: #7ec0f2 !default;
$blue-sky-light: #c8e8fd !default;
$blue-gray-light: #c1d7e1 !default;
$blue-gray: #aabcc5 !default;
$deep-blue: #2c85c0 !default;
$blue-very-light: #dfeef5 !default;
$green: #5ea348 !default;
$apple-green: #57bb3a !default;
$color-subnetwork: #666666 !default;
$error-color: #f05757 !default;
$orange: orange !default;
$red: #c22224 !default;
$red-trip: #b82522 !default;
$blue-post: #469be1 !default;
$purple-event: #9b3796 !default;
$light-red: #fdd0d0 !default;

// Social network official color
$facebook-color: #3b5998 !default;
$twitter-color: #00aced !default;
$google-plus-color: #dd4b39 !default;
$youtube-color: #bb0000 !default;
$linkedin-color: #0077b5 !default;
$viadeo-color: #fff !default;
$flickr-color: #ff0084 !default;
$instagram-color: #517fa4 !default;
$dailymotion-color: #006894 !default;
$pinterest-color: #cb2027 !default;
$xing-color: #b0d400 !default;

$side-menu-item-active: #e5e5e5 !default;
$site-background-color: #f7f7f7 !default;
$sidebar-color: #f4f5f5 !default;
$bo-header-color: $sidebar-color;
$side-menu-color: $sidebar-color;
$link-color: #3078ca !default;
$tab-color: white !default;

$action-icon-color: #8892a0 !default;
$action-icon-color-hover: #505357 !default;
$bo_background_page_color: #fcfcfc !default;

/**
 * DS colors
 */
$dove: #737373 !default;
