$icon-width: 60px;
$icon-color: $blue;

.user-data-custom-field-choose-type-modal__item {
  display: block;
  font-size: 0.9em;
  color: #42484c;
  padding: 1em;
  border: 1px solid transparent;
  margin-bottom: 0.5em;

  &:hover {
    background-color: $white;
    border-color: $light-gray;
  }
}

.user-data-custom-field-choose-type-modal__icon {
  display: inline-block;
  font-size: 2.4em;
  width: $icon-width;
  height: $icon-width;
  line-height: $icon-width;
  border: 1px solid $light-gray;
  margin-bottom: 0.3em;
  color: $icon-color;
  box-shadow: 0 1px 2px $light-gray;
}

.user-data-custom-field-choose-type-modal__title {
  font-weight: 600;
  margin-bottom: 0.1em;
}

.user-data-custom-field-choose-type-modal__description {
  font-size: 0.85em;
  font-weight: 600;
  line-height: 1.4em;
  color: #b5b5b5;
}
