.image-preview {
  border-radius: 3px;
  border: none!important;
}

.image-fluid {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
