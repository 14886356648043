@mixin hook-table-cell {
  color: #050505;
  font-size: 13px;
}

@mixin hook-table-header-cell {
  vertical-align: middle;

  i {
    margin-left: 0.4em;
    cursor: pointer;
  }
}

@mixin hook-table-misc {
  // error line
  .uk-table-hover tbody tr.uk-danger {
    background: lighten($red, 35%);

    &:hover {
      background: lighten($red, 32%);
    }
  }

  .uk-table th {
    font-weight: 600;
  }
}
