@import '../../../helpers/_bo_colors.scss';

// Colors
$global-primary-background: $blue;
$global-primary-background--300: $blue--300;
$global-primary-background--700: $blue--700;
$global-link-color: $global-primary-background;
$global-secondary-background: white;

// Font-family
$global-font-family: 'Inter', 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif !default;

// Font-size
$global-small-font-size: 10px;
$global-medium-font-size: 12px;
$global-font-size: 14px;
$global-large-font-size: 18px;
$global-xlarge-font-size: 24px;
$global-xxlarge-font-size: 32px;

// H1
$base-h1-font-size: $global-xxlarge-font-size !default;

// H2
$base-h2-font-size: $global-xxlarge-font-size * 0.8 !default;

// H3
$base-h3-font-size: $global-xxlarge-font-size * 0.7 !default;

// H4
$base-h4-font-size: $global-xxlarge-font-size * 0.6 !default;

// H5
$base-h5-font-size: $global-xxlarge-font-size * 0.5 !default;

// Gutter
$global-gutter: 24px;
