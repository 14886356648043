.select2-container {
  .select2-selection__arrow,
  .select2-selection__rendered,
  .select2-selection {
    min-height: 2.55em !important;
  }

  .select2-selection--single {
    min-height: 40px !important;
    border: 1px solid #2b2b2b;
  }

  .select2-selection--single .select2-selection__rendered {
    color: #2b2b2b !important;
    line-height: 40px !important;
  }

  .select2-selection__choice {
    padding: 0.1em 1.1em 0.1em 0.9em !important;
    margin: 0.3em 0.3em 0.3em 0 !important;
    white-space: normal;
    line-height: 2em;
  }
}

.select2-container--focus .select2-selection--multiple {
  border-width: 1px !important;
}

.datepicker--select {
  .select2-container {
    display: inline-block !important;
    width: 33% !important;
  }
}

.select2-container {
  max-width: 100% !important;
  min-width: 100% !important;
}
.select2-container .select2-selection--multiple {
  min-height: 26px;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #2b2b2b;

  .select2-selection__rendered {
    display: inherit;
  }
}

.select2-container .select2-selection .select2-selection__choice {
  font-size: 13px;
  padding: 0.3em 1.1em 0.3em 0.9em;
}

.select2-results__option:empty {
  display: none;
}
