.user-data-custom-field-form-select__options {
  padding-left: 1.5em;

  input[type=text] {
    width: 50%;
    font-size: 0.9em;
    padding: 0.2em 0.8em;
    margin-bottom: .5em;
    border-radius: 3px;
    height: 20px;
  }
}
